import React from 'react';

const ProfileImage = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1080 1080"
    style={{ enableBackground: "new 0 0 1080 1080" }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
        .st0{fill:var(--primary);}
        .st1{fill:#F2F2F2;}
        .st2{fill:none;stroke:#F2F2F2;stroke-width:27;stroke-linecap:round;stroke-miterlimit:10;}
      `}
    </style>
    <g>
      <rect x="0.5" y="0.5" className="st0" width="1080" height="1080" />
      <path d="M1080,1v1079H1V1H1080 M1081,0H0v1081h1081V0L1081,0z" />
    </g>
    <g>
      <g>
        <path
          className="st1"
          d="M540,157.8c42.1,0,81.6,16.4,111.4,46.1c29.7,29.7,46.1,69.3,46.1,111.4s-16.4,81.6-46.1,111.4
          c-29.7,29.7-69.3,46.1-111.4,46.1s-81.6-16.4-111.4-46.1s-46.1-69.3-46.1-111.4s16.4-81.6,46.1-111.4S497.9,157.8,540,157.8
          M540,130.8c-101.9,0-184.5,82.6-184.5,184.5S438.1,499.8,540,499.8s184.5-82.6,184.5-184.5S641.9,130.8,540,130.8L540,130.8z"
        />
      </g>
      <path
        className="st2"
        d="M181.5,949.2c0-198,160.5-358.5,358.5-358.5s358.5,160.5,358.5,358.5"
      />
    </g>
  </svg>
);

export default ProfileImage;
