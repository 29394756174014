import React, { useEffect, useState } from 'react'
import '../styles/formPage.css'
import { url } from '../URL'
import axios from 'axios'
function AddSession() {
    const [data, setData] = useState({
        patient:"",
        service:"",
        category:"",
        publisher:"",
        cost:0,
        start:'',
        startHour:"",
        responsible:"",
        responsibleID:"",
        end:"",
        number:1,
        discount:0,
        paid:0,
        remaining:0,
        notes:""
    })
    const [suggestion, setSuggestion] = useState("")
    const [patientID, setPatientID] = useState('')
    const [responsibleID, setResponsibleID] = useState('')
    // const [publisherID, setPublisherID] = useState('')
    const [msg, setMsg] = useState('')
    const [dropdowns, setDropdowns] = useState({
        service:false,
        category:false,
        wavelength:false,
        responsible:false
    })
    const [categories, setCategories] = useState([])
    const [patients, setPatients] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [services, setServices] = useState([])
    function openDropdown(dropdown){
        setDropdowns({
            service: dropdown === 'service' ? true : false,
            category: dropdown === 'category' ? true : false,
            wavelength: dropdown === 'wavelength' ? true : false,
            responsible: dropdown === 'responsible' ? true : false,
        })
    }
    const [isLoading, setIsLoading] = useState(true)

    const [laserData, setLaserData] = useState({
        wavelength:"",
        lensNumber:0,
        machineName:'',
        pulseDuration:0,
        count:0,
        fluenceNumber:0,
        notes:""
    })
 
    const [employees, setEmployees] = useState([])
    async function loadData(){
        await axios.get(`${url}/sessions/patients/`, {withCredentials:true})
                        .then(res => {
                            setPatients(res.data.data)
                        })
                        .catch(err => {
                            console.log(err.response.data.message)
                        })
        await axios.get(`${url}/sessions/category/`, {withCredentials:true})
                    .then(res => {
                        setCategories(res.data.data)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                    })
        await axios.get(`${url}/sessions/employees/`, {withCredentials:true})
                    .then(res => {
                        setIsLoading(false)
                        setEmployees(res.data.data)
                    })
                    .catch(err => {
                        setIsLoading(false)
                        console.log(err.response.data.message)
                    })
    }
    useEffect(() => {
        if(isLoading){
            loadData()
        }
    }, [isLoading])
    useEffect(() => {
        if(data.patient){
            var p = patients.find(d => {return d.name.includes(data.patient)})
            if(p){
                setSuggestion(p)
            }else {
                setSuggestion('')
            }
        }
        window.addEventListener('keyup', (e) => {
            if(e.key === 'ArrowLeft' && suggestion){
                setData((d) => {return {...d, patient:suggestion.name}})
                setPatientID(suggestion._id)
            }
        })
    }, [data.patient,suggestion, patients])
    async function createSession(){
        await axios.post(`${url}/sessions/create`, {
            data:{...data, ...laserData, responsibleID, publisher:"", patientID, remaining:getRemaining(data.cost, data.discount, data.paid, data.number), count:data.number}
        }, {withCredentials:true})
                .then(res => {
                    console.log(res.data.data)
                    setMsg(res.data.message)
                })
                .catch(err => {
                    if(err){
                        console.log(err.response.data.message)
                        setMsg(err.response.data.message)
                        if(err.response.status === 400){
                            window.location = '/login'
                          }
                    }
                })
    }
    async function updateServices(category){
        await axios.get(`${url}/sessions/services/get-services?category=${category}`, {withCredentials:true})
                    .then(res => {
                        setServices(res.data.data)
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                    })
    }


    function getDiscount(price, discount, number){
        return (price * number) - discount
    }

    function getRemaining(price, discount, paid, number){
        const totalPrice = price * number 
        const priceAfterDiscount = totalPrice - discount
        const remaining = priceAfterDiscount - paid 
        return remaining
    }

    // useEffect(() => {
    //     getDiscount(data.cost, data.discount)
    // }, [data.cost, data.discount])
    useEffect(() => {
        updateServices(categoryId)
        if(!data.category.includes('ليزر')){
            setLaserData({
                wavelength:"",
                lensNumber:0,
                machineName:'',
                pulseDuration:0,
                count:0,
                fluenceNumber:0
            })
        }
    }, [categoryId, data.category])

    return (
    <div className='form-page add-session'>
        <div className='form'>
            <form>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                    <div className='field-container'>
                        <input className={data.patient === '' ? 'field' : 'field active'} type='text' name='name' value={data.patient} onChange={(e) => {setData(d => {return {...d, patient:e.target.value}})}}  />
                        <label className={data.patient === '' ? '' : 'active'} htmlFor='name'>الأسم</label>
                        <p className={suggestion && data.patient !== suggestion && data.patient !== '' ? 'suggessions active' : 'suggessions'}>{suggestion.name}</p>
                    </div>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>المسؤول عن الجلسة</label>
                        <div className='category'  onClick={() => {
                        openDropdown('responsible')
                        }}
                        >{data.responsible || "غير محدد"}</div>
                        <div  className={dropdowns.responsible ? 'dropdown active' : 'dropdown'}>
                        <ul>
                            {
                                employees ? employees.map((employee, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d) => {return {...d, responsible:employee.name}})
                                            // setCategoryId(category._id)
                                            setResponsibleID(employee._id)
                                            setData(d => {return {...d, responsible:employee.name}})
                                            openDropdown('')
                                        }}>{employee.name}</li>
                                    )
                                }) : "خطأ"
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                <div className='field-container'>
                        <input className={data.start === '' ? 'field' : 'field active'} type='date' name='start' value={data.start} onChange={(e) => {setData(d => {return {...d, start:e.target.value}})}}  />
                        <label className={data.start === '' ? '' : 'active'} htmlFor='name'>تاريخ الجلسة</label>
                    </div>
                    <div className='field-container'>
                        <input className={data.startHour === '' ? 'field' : 'field active'} type='time' name='startHour' value={data.startHour} onChange={(e) => {setData(d => {
                            return {...d, startHour:e.target.value}})}}  />
                        <label className={data.startHour === '' ? '' : 'active'} htmlFor='name'>وقت الجلسة</label>
                    </div>
                </div>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>الفئة</label>
                        <div className='category'  onClick={() => {
                        openDropdown('category')
                        }}
                        >{data.category || "غير محدد"}</div>
                        <div  className={dropdowns.category ? 'dropdown active' : 'dropdown'}>
                        <ul>
                            {
                                categories.map((category, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d) => {return {...d, category:category.name, service:''}})
                                            setCategoryId(category._id)
                                            openDropdown('')
                                        }}>{category.name}</li>
                                    )
                                }) 
                            }
                        </ul>
                        </div>
                    </div>
                    <div className={'field-container dropdown-container'}>
                        <label className='active'>الخدمة</label>
                        <div className='service'  onClick={() => {
                        openDropdown('service')
                        }}
                    >{data.service || "غير محدد"}</div>
                        <div  className={dropdowns.service ? 'dropdown active' : "dropdown"}>
                        <ul>
                            {
                                services ? services.map((service, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            setData((d => {return {...d, service: service.name, cost:service.price}}))
                                            openDropdown('')
                                        }}>{service.name}</li>
                                    )
                                }) : ''
                            }
                        </ul>
                        </div>
                    </div>
                </div>
                <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(6, 1fr)", gap:".5rem"}}>
                    <div className='field-container'>
                        <input className={data.number === '' ? 'field' : 'field active'} type='number' min={1} step={1} name='number' value={data.number} onChange={(e) => {setData(d => {return {...d, number:e.target.value}})}}  />
                        <label className={data.number === '' ? '' : 'active'} htmlFor='number'>عدد الجلسات</label>
                    </div>
                    <div className='field-container'>
                        <input className={data.cost === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={data.cost} onChange={(e) => {setData(d => {return {...d, cost:e.target.value}})}}  />
                        <label className={data.cost === '' ? '' : 'active'} htmlFor='cost'>سعر الجلسة</label>
                    </div>
                    <div className='field-container'>
                        <input className={data.discount === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={data.discount} onChange={(e) => {setData(d => {return {...d, discount:e.target.value}})}}  />
                        <label className={data.discount === '' ? '' : 'active'} htmlFor='cost'>الخصم</label>
                    </div>
                    <div className='field-container disabled'>
                        <input className='field active' disabled type='number' min={0} step={1000} name='cost' value={getDiscount(data.cost, data.discount, data.number)} />
                        <label className='active' htmlFor='cost'>المبلغ بعد الخصم</label>
                    </div>
                    <div className='field-container'>
                        <input className={data.paid === '' ? 'field' : 'field active'} type='number' min={0} step={1000} name='cost' value={data.paid} onChange={(e) => {setData(d => {return {...d, paid:e.target.value}})}}  />
                        <label className={data.paid === '' ? '' : 'active'} htmlFor='cost'>المدفوع</label>
                    </div>
                    <div className='field-container disabled'>
                        <input className='field active' disabled type='number' min={0} step={1000} name='remaining' value={getRemaining(data.cost, data.discount, data.paid, data.number)} onChange={(e) => {setData(d => {return {...d, cost:e.target.value}})}}  />
                        <label className='active' htmlFor='remaining'>المتبقي</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='field-container'>
                        <input className='field active' type='text' name='notes' value={data.notes} onChange={(e) => {setData(d => {return {...d, notes:e.target.value}})}}  />
                        <label className='active' htmlFor='notes'>ملاحظات</label>
                    </div>
                </div>
                <div className='row'>
                    <button onClick={(e) => {
                        e.preventDefault()
                        createSession()
                    }}>اضافة</button>
                </div>
            </form>
            <p style={{fontSize:"var(--paragraph)", color:"var(--primary)", textAlign:"center", marginTop:"1rem"}}>{msg}</p>
        </div>
    </div>
  )
}

export default AddSession