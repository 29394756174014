import React from 'react'
import '../styles/Alert.css'
import { BsExclamationTriangle } from 'react-icons/bs'
function Alert(props) {
  return (
    <div className={props.classText}>
        <div className='head'>
            <div className='icon'>
                <BsExclamationTriangle />
            </div>
            <div className='title'>
                <h3>!تحذير</h3>
            </div>
        </div>
        <div className='message'>
            <p>
                سيؤدي هذا النشاط الى حذف او تعديل البيانات بشكل نهائي, هل انت متاكد من قيامك بهذا النشاط؟    
            </p>
        </div>
        <div className='buttons'>
            <button className='do' onClick={props.do}>
                نعم, قم بهذا النشاط
            </button>
            <button className='dont' onClick={props.dont}>
                لا, الغاء النشاط
            </button>
        </div>
    </div>
  )
}

export default Alert