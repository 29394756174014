import React, { useState, useEffect } from 'react'
import { BsCheck, BsSearch } from 'react-icons/bs'
import '../styles/formPage.css'
import axios from 'axios'
import { url } from '../URL'
import getTime from './getTime'
import formatDate from './formatDate'
import UnAuthorized from './UnAuthorized'
import LaserDetails from './LaserDetails'

function DoneSessions() {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [msg, setMsg] = useState('')
  const [unAuthorized, setUnAuthorized] = useState(false)
  async function loadData() {
    await axios.get(`${url}/sessions/?status=done`, {withCredentials:true})
      .then(res => {
        const d = res.data.data
        setData(d);
        setIsLoading(false)
      })
      .catch(err => {
        if(err.response.status === 401){
          setUnAuthorized(true)
        }
        setMsg(err.response.data.message)
        console.log(err)
        setIsLoading(false)
      })
  }


  useEffect(() => {
    if (isLoading) {
      loadData()
    }
  }, [isLoading])
  function filterData(d){
    return d.filter(data => search !== '' ? data.patient.includes(search) : data).filter(d => {
      return from !== '' ? new Date(d.start) >= new Date(from) : d
    }).filter(d => {
      return to !== '' ? new Date(d.start) <= new Date(to) : d
    })
  }
  

  
  function sortData(data){
    const sorted = data.sort((a, b) => {
      const dateA = new Date(`${a.start}`);
      const dateB = new Date(`${b.start}`);
      return dateB - dateA;
    })
    return sorted
  };
  const [showNotes, setShowNotes] = useState(true)
  const [areDetailsActive, setAreDetailsActive] = useState(false)
  const [currentSession, setCurrentSection] = useState({})
  async function showDetails(cSessions){
    setCurrentSection(cSessions)
    setAreDetailsActive(true)
  }
  return unAuthorized ? <UnAuthorized /> : (
    <main className='page-container patients-page form-page'>
      <LaserDetails isActive={areDetailsActive} data={currentSession} />
      <div className={areDetailsActive ? 'shadow active' : 'shadow'} onClick={() => setAreDetailsActive(false)}></div>
      <div className='container'>
        <section className='head-section' style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <h1 className='title'>جلسات مكتملة</h1>
          </div>
          <div>
            <h2 style={{ color: "var(--primary)" }}>العدد: {filterData(data).length}</h2>
          </div>
        </section>
        <section className='filter patients' style={{gridTemplateColumns:".6fr .2fr .2fr"}}>
          <div className='field-container'>
            <label>البحث</label>
            <div className='search-bar-grid'>
              <div className='icon'>
                <BsSearch />
              </div>
              <div className='search-bar'>
                <input type='text' placeholder='بحث...' className='field' value={search} onChange={e => { setSearch(e.target.value) }} />
              </div>
            </div>
          </div>
          <div className='field-container'>
            <label>من</label>
            <input type='date' style={{borderRadius:"7px"}} className='field' value={from} onChange={e => { setFrom(e.target.value) }} />
          </div>
          <div className='field-container'>
            <label>ألى</label>
            <input type='date' style={{borderRadius:"7px"}} className='field' value={to} onChange={e => { setTo(e.target.value) }} />
          </div>
        </section>
        <section className='filter patients'>
          <div className='field-container'>
            <label>خيارات</label>
            <div className='checkbox-container'>
              <div className={showNotes ? 'checkbox active' : 'checkbox'} onClick={() => {
                setShowNotes(!showNotes)
              }}>
                {
                  showNotes ? <BsCheck /> : ''
                }
              </div>
              <div>
                اظهار الملاحظات
              </div>
            </div>
          </div>
        </section>
        <section className='patients'>
          <div className='table-container'>
            {
              filterData(data).length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>
                        أسم المراجع
                      </th>
                      <th>
                        الخدمة
                      </th>
                      <th>
                        التاريخ
                      </th>
                      <th>
                        الوقت
                      </th>
                      <th>
                        المسؤول عن الجلسة
                      </th>
                      {
                        showNotes ? (
                          <th>
                        ملاحظات
                      </th>
                        ): ""
                      }
                       <th>
                        خيارات
                      </th>
                       <th>
                        حالة التعديل
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sortData(filterData(data)).map((d, i) => {
                        return (
                          <tr key={i}>
                            <td>{d.patient}</td>
                            <td>{d.service}</td>
                            <td>{formatDate(d.start)}</td>
                            <td>{getTime(d.startHour)}</td>
                            <td>{d.responsible}</td>
                            {showNotes ? <td>{d.notes || "لا يوجد"}</td> : ''}
                            <td>
                              {
                                d.service.includes("ليزر") ? <button 
                                onClick={() => {
                                  showDetails(d)
                                }}>تفاصيل الجلسة</button> : ''
                              }
                            </td>
                            <td>
                              <div className='isEdited'>
                                {d.isEdited ? "معدلة" : "غير معدلة"}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              ) : (
                <div className='not-found'>
                  <div className='image'>
                    <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
                  </div>
                  <div>
                    <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
                  </div>
                </div>
              )
            }
          </div>
          <p>{msg}</p>
        </section>
      </div>
    </main>
  )
}

export default DoneSessions
