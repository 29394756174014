import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { url } from '../URL'
function EmployeeSalary(props) {

    const [salaries, setSalaries] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    function getTotalSalary(salary){
        if(salary.length > 0){
            const amounts = salary.map(s => {
                return s.amount
            })
            const total = amounts.reduce((d1, d2) => {return d1 + d2})
            return total
        }
    }
    const total = getTotalSalary(salaries)

    async function loadData(){
        await axios.get(`${url}/employees/${props.id}/salary`, {withCredentials:true}) 
                    .then(res => {
                        setSalaries(res.data.data)
                        setIsLoading(false)
                    })                   
                    .catch(err => {
                        console.log(err.response.data.message)
                        setIsLoading(false)
                    })
    }
    useEffect(() => {
        if(isLoading){
            loadData()
        }
    })
  return (
    <>
                <div className='section-title'>
                    <h1>الرواتب</h1>
                </div>
                <div className='salaries'>
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    المبلغ
                                </td>
                                <td>
                                    تاريخ الأستلام
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                salaries.map((salary, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{salary.amount} د.ع</td>
                                            <td>{salary.recieved}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className='total'>
                        <h3>مجموع الراتب المستلم هو: <span>{total}</span></h3>
                    </div>
                </div>
            </>
  )
}

export default EmployeeSalary