import React, {useState, useEffect} from 'react'
import '../styles/formPage.css'
import axios from 'axios'
import { url } from '../URL'
import Loading from './Loading'
import {Link, useSearchParams } from 'react-router-dom'
function Cart() {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [quantities, setQuantities] = useState({})
  const [params] = useSearchParams()

  async function loadCart(id){

    await axios.get(`${url}/cart/${id}`, {withCredentials:true})
                .then(res => {
                  setData(res.data.data)
                  res.data.data.products.forEach(d => {
                    setQuantities(q => {
                      return {...q, [d._id]: d.soldQuantity}
                    })
                  })
                  setIsLoading(false)
                })
                .catch(err => {
                  console.log(err)
                  setIsLoading(false)
                })
  }
  useEffect(() => {
    if(isLoading){
      const cartId = params.get('cart')
      loadCart(cartId)
      
    }
  })

  async function checkout(id){
    await axios.put(`${url}/cart/${id}/checkout`, {}, {withCredentials:true})
                .then(res => {
                  window.location = '/materials/sell/choose-patient'
                })
                .catch(err => {
                  console.log(err)
                })
  }
  return (
    <main className='page-container patients-page form-page sell-product-page'>
      <div className='container'>
        <section className='head-section' style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <div>
            <h2 className='title'>الخطوة الثالثة: اتمام الشراء</h2>
            <h1 className='title'>جميع المنتجات التي تم اختياريها</h1>
          </div>
        </section>
        <section className='patients'>
          {
            data.products && data.products.length > 0 ? isLoading ? <Loading />  : (
              <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  أسم المنتج
                </th>
                <th>
                  سعر القطعة
                </th>
                <th>
                  العدد
                </th>
                <th>
                  مجموع سعر المنتج
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.products.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>{d.name}</td>
                      <td>{d.sellingPrice} د.ع</td>
                      <td>
                      {quantities[d._id]}
                      </td>
                      {/* <td className='quantity-container'>
                        <div onClick={() => {
                          const currentQuantity = quantities[d._id]
                          setQuantities(q => {
                            return {...q, [d._id]:currentQuantity + 1}
                          })
                        }}>
                          <BsPlus />
                        </div>
                        <input className='field' type='number' value={quantities[d._id]} onChange={e => {
                          // setQuantities(r => {
                          //   return {...r, [d._id]:parseInt(e.target.value)}
                          // })
                        }} name='quantity' min={0} />
                        <div onClick={() => {
                          // const currentQuantity = quantities[d._id]
                          // setQuantities(q => {
                          //   return {...q, [d._id]:currentQuantity - 1 >= 0 ? currentQuantity - 1 : 0}
                          // })
                        }}>
                          <BsDash />
                        </div>
                      </td> */}
                      <td >{d.sellingPrice * quantities[d._id]} د.ع</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <Link to={`/materials/sell/add-products?cart=${params.get('cart')}`}>
              <button style={{background:"var(--accent)", width:"fit-content", color:"var(--bg)", marginTop:".5rem"}}>تغيير المنتجات</button>
          </Link>
          <button onClick={e => {
            e.preventDefault()
            const cartId = params.get('cart')
            checkout(cartId)
          }} style={{background:"var(--primary)", width:"fit-content", color:"var(--bg)", marginTop:".5rem"}}>أتمام الشراء</button>
          </div>
            ) : (
              <div className='not-found' style={{display: "flex"}}>
            <div className='image'>
              <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
            </div>
            <div>
              <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
            </div>
          </div>
            )
          }
          
        </section>
      </div>  
    </main>        
  )
}

export default Cart