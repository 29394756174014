import React, {useEffect, useState} from 'react'
import '../styles/Employee.css'
import '../styles/formPage.css'
import Alert from '../Components/Alert'
import axios from 'axios'
import { url } from '../URL'
import { useLocation } from 'react-router-dom'
import ProfileImage from '../Assets/Images/profile'
import EmployeeSalary from '../Components/EmployeeSalary'
import formatDate from '../Components/formatDate'
import EmployeeSessions from '../Components/EmployeeSessions'
import UnAuthorized from '../Components/UnAuthorized'
function Employee() {
    const [isAlertActive, setIsAlertActive] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [sectionIndex, setSectionIndex] = useState(0)
    const [unauthorized, setUnauthorized] = useState(false)
    async function loadData(id){
        await axios.get(`${url}/employees/${id}`, {withCredentials:true})
                    .then(res => {
                        setData(res.data.data)
                        setIsLoading(false)
                    })
                    .catch(err => {
                        if(err.response.status === 401){
                            setUnauthorized(true)
                        }
                        console.log(err.response.data.message)
                        setIsLoading(false)
                    })
    }
    const path = useLocation()
    useEffect(() => {
        if(isLoading){
            const pathArr = path.pathname.split('/')
            const id = pathArr[pathArr.length - 1]
            loadData(id)
        }
    })
    async function deleteEmployee(id){
        await axios.delete(`${url}/employees/${id}/delete`, {withCredentials:true})
                    .then(res => {
                        if(res.status === 200){
                            window.location = '/employees'
                        }
                    })
                    .catch(err => {
                        console.log(err.response.data.message)
                    })
    }
    return unauthorized ? <UnAuthorized /> : (
    !isLoading ? (
        <main className='employee-page page-container form-page'>
        <div className={isAlertActive ? 'shadow active' : 'shadow'} onClick={() => {setIsAlertActive(false)}}></div>
        <Alert classText={isAlertActive ? 'alert active' : 'alert'} dont={() => {setIsAlertActive(false)}} do={() => {
            deleteEmployee(data._id)
        }} />
        <div className='container'>
            <div className='profile-details'>
                <div className='image'>
                    <ProfileImage />
                </div>
                <div className='details'>
                    <ul>
                        <li>
                            <div className='label'>
                                الأسم:
                            </div>
                            <div>{data.name}</div>
                        </li>
                        <li>
                            <div className='label'>
                                العمر:
                            </div>
                            <div>{data.age}</div>
                        </li>
                        
                        <li>
                            <div className='label'>
                                يعمل منذ:
                            </div>
                            <div>{formatDate(data.startedAt)}</div>
                        </li>
                        
                        <li>
                            <div className='label'>
                                الراتب:
                            </div>
                            <div>{data.salary} د.ع</div>
                        </li>
                        
                        <li>
                            <div className='label'>
                                الوظيفة:
                            </div>
                            <div>{data.role}</div>
                        </li>
                        <li>
                            <div className='label'>
                                رقم الهاتف:
                            </div>
                            <div>{data.phone}</div>
                        </li>
                        <li>
                            <button onClick={() => {
                                setIsAlertActive(true)
                            }}>حذف</button>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div>
                <div className='title'>
                    <h1>المعلومات</h1>
                </div>
                <div className='options'>
                    <ul>
                        <li className={sectionIndex === 0 ? 'active' : ''} onClick={() => {setSectionIndex(0)}}>الرواتب</li>
                        <li className={sectionIndex === 1 ? 'active' : ''} onClick={() => {setSectionIndex(1)}}>الجلسات</li>
                    </ul>
                </div>
                {
                    sectionIndex === 0 ? 
                    <EmployeeSalary id={data._id} /> : 
                    <EmployeeSessions id={data._id} />
                }
            </div>
        </div>
    </main>
    ) : 'Loading...'
  )
}

export default Employee