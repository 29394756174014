import React, { useState } from 'react'
import '../styles/Sessions.css'
import AddSession from '../Components/AddSession'
import PendingSessions from '../Components/PendingSessions'
import DoneSessions from '../Components/DoneSessions'
import AllSessions from '../Components/AllSessions'
function Sessions() {
  const [currentSection, setCurrentSection] = useState(0)
  return (
    <main className='page-container sessions-page'>
      <section className='head-title'>
        <h1 className='title'>
          الجلسات
        </h1>
      </section>
      <section className='options'>
        <ul>
          <li className={currentSection === 0 ? "active " : ''} onClick={() => {setCurrentSection(0)}}>
            أضافة جلسة
          </li>
          <li className={currentSection === 1 ? "active " : ''} onClick={() => {setCurrentSection(1)}}>
            جميع الجلسات
          </li>
          <li className={currentSection === 2 ? "active " : ''} onClick={() => {setCurrentSection(2)}}>
            قيد الانتظار
          </li>
          <li className={currentSection === 3 ? "active " : ''} onClick={() => {setCurrentSection(3)}}>
            مكتملة
          </li>
        </ul>
      </section>
      <section className='sessions-content'>
        {
          currentSection === 0 ? <AddSession /> : 
          currentSection === 1 ? <AllSessions /> : currentSection === 2 ? <PendingSessions /> : 
          <DoneSessions />
        }
      </section>
    </main>
  )
}

export default Sessions