import React from 'react'
import {BsArrowClockwise} from 'react-icons/bs'
function LoadingIcon(props) {
  return (
    <div className='icon loading-icon' style={{transition:'.3s ease', opacity: props.active ? 1 : 0}}>
        <BsArrowClockwise />
    </div>
  )
}

export default LoadingIcon