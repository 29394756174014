import React from 'react'
import '../styles/Unauthorized.css'
function UnAuthorized() {
  return (
    <main id='unauthorized'>
        <div className='image'>
            <img src={require("../Assets/Images/401 Error Unauthorized-bro.webp")} alt='401 Unauthorized' loading='lazy' />
        </div>
        <div>
            <h1>هذا المستخدم غير مصرح له بالدخول</h1>
        </div>
    </main>
  )
}

export default UnAuthorized