import React, {useState, useEffect} from 'react'
import { BsSearch } from 'react-icons/bs'
import '../styles/formPage.css'
import axios from 'axios'
import { url } from '../URL'
import Loading from './Loading'
import UnAuthorized from '../Components/UnAuthorized'
function ChoosePatient() {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [dropdowns, setDropdowns] = useState({
    gender:false,
    service:false
  })
  const [gender, setGender] = useState("")
  const [search, setSearch] = useState("")
  const [unAuthorized, setUnAuthorized] = useState(false)

  function openDropdown(dropdown){
    setDropdowns({
      gender: dropdown === 'gender' ? true : false,
      service: dropdown === 'service' ? true : false,
    })
  }

  const filteredData = data.filter(d => gender !== '' ? d.gender.replace('أ', 'ا') === gender.replace('أ', 'ا') : d).filter(d => d.name.includes(search))
  async function loadData(){
    await axios.get(`${url}/patients/get`, {
      withCredentials:true
          })
              .then(res => {
                setData(res.data.data)
                setIsLoading(false)
              })
              .catch(err => {
                  console.log(err)
                  setIsLoading(false)
                if(err.response.status === 401){
                  setUnAuthorized(true)
                }
              })
  }
  useEffect(() => {
    if(isLoading){
      loadData()
    }
  }, [isLoading])
  async function createCart(data){
    await axios.post(`${url}/cart/create`, {data}, {withCredentials:true})
              .then(res => {
                if(res.status === 201){
                  const cartId = res.data.data
                  window.location = `/materials/sell/add-products?cart=${cartId}`
                }
              })
              .catch(err => {
                console.log(err.response.data)
              })
  }
  return unAuthorized ? <UnAuthorized /> : (
    <main className='page-container patients-page form-page'>
      <div className='container'>
        <section className='head-section' style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
          <div>
            <h2 className='title'>الخطوة الأولى: أختار المراجع</h2>
            <h1 className='title'>جميع المراجعين</h1>
          </div>
          <div>
            <h2 style={{color:"var(--primary)"}}>العدد: {filteredData.length}</h2>
          </div>
        </section>
        <section className='filter patients' style={{gridTemplateColumns:".8fr .2fr"}}>
          <div className='field-container'>
            <label>البحث</label>
            <div className='search-bar-grid'>
              <div className='icon'>
                <BsSearch />
              </div>
              <div className='search-bar'>
                <input type='text' placeholder='بحث...' className='field' value={search} onChange={e => {setSearch(e.target.value)}} />
              </div>
            </div>
          </div>
            <div>
              <label>الجنس</label>
                <div className='dropdown-container'>
                  <div className='gender' onClick={() => {openDropdown("gender")}}>{gender || 'الجميع'}</div>
                  <div className={dropdowns.gender ? 'dropdown active' : 'dropdown'}>
                    <ul>
                      <li onClick={() => {
                        openDropdown('')
                        setGender("")
                      }}>الجميع</li>
                      <li onClick={() => {
                        openDropdown('')
                        setGender("ذكر")
                      }}>ذكر</li>
                      <li onClick={() => {
                        openDropdown('')
                        setGender("أنثى")
                      }}>انثى</li>
                    </ul>
                  </div>
                </div>
            </div>
        </section>
        <section className='patients'>
          {
            filteredData.length > 0 ? isLoading ? <Loading />  : (
              <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  أسم المراجع
                </th>
                <th>
                  العمر
                </th>
                <th>
                  رقم الهاتف
                </th>
                <th>
                  العنوان
                </th>
                <th>
                  أختيار
                </th>
              </tr>
            </thead>
            <tbody>
              {
                filteredData.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>{d.name}</td>
                      <td>{d.age}</td>
                      <td>{d.phone}</td>
                      <td>{d.address}</td>
                      <td>
                          <button onClick={e => {
                            e.preventDefault()
                          
                            createCart({
                              patientName:d.name,
                              patient:d._id
                            })
                          }}>أختار</button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          </div>
            ) : (
              <div className='not-found' style={{display: "flex"}}>
            <div className='image'>
              <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
            </div>
            <div>
              <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
            </div>
          </div>
            )
          }
          
        </section>
      </div>  

    </main>        
  )
}

export default ChoosePatient