import React from 'react'
import '../styles/logoutAlert.css'
import { url } from '../URL'
import axios from 'axios'
function logoutAlert(props) {


  async function logout(){
    await axios.post(`${url}/auth/logout`, {}, {withCredentials:true})
              .then(res => {
                if(res.status === 200){
                  window.location = '/login'
                }
              })
              .catch(err => {
                console.log(err)
              })
  }
  return (
    <div id='logoutAlert' className={props.isActive ? "active" : ''}>
        <h3>هل انت متاكد من القيام بتسجيل الخروج؟</h3>
        <div className='btns'>
            <button className='yes' onClick={(e) => {
                e.preventDefault()
                logout()
            }}>نعم, تسجيل الخروج</button>
            <button className='no' onClick={(e) => {
                e.preventDefault()
                props.cancel()
            }}>لا, الغاء</button>
        </div>
    </div>
  )
}

export default logoutAlert