import React, {useState} from 'react'
import '../styles/CosmeticsMaterials.css'
import AddStorageMaterials from '../Components/AddStorageMaterials'
import AllStorageMaterials from '../Components/AllStorageMaterials'
function StorageMaterials() {
  const [steps, setSteps] = useState(1)
  return (
    <main className='form-page cosmetics-page page-container'>
      <section className='head-section'>
        <h1>مواد المخزن</h1>
        <ul>
          <li className='active' onClick={() => {setSteps(1)}}>
            اضافة مواد
          </li>
          <li onClick={() => {setSteps(2)}}>
            جميع المواد
          </li>
          <li className={`hover step-${steps}`}></li>
        </ul>
      </section>
      <section className='content'> 
        {
          steps === 1 ? <AddStorageMaterials /> : <AllStorageMaterials />
        }
      </section>
    </main>
  )
}

export default StorageMaterials