import React from 'react'
import '../styles/layout.css'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'

function Layout({children}) {
  return (
    <main className='layout'>
      <Sidebar />
      <main>
        <Header />
        {children}
      </main>
    </main>
  )
}

export default Layout