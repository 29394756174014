import React, {useEffect, useState} from 'react'
import { url } from '../URL'
import axios from 'axios'
import formatDate from './formatDate'
import getTime from './getTime'
import sessionsStatus from './SessionsStatus'
function EmployeeSessions(props) {

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState({
    time:1,
    status:""
  })
  const [filteredData, setFilteredData] = useState([])
  const timeKeys = {
    1:"اليوم",
    7:"الأسبوع الماضي",
    30:"الشهر الماضي",
    100000:"كل الوقت",
  }
  const [dropdowns, setDropdowns] = useState({
    time:false,
    status:false
  })
  
  function openDropdown(dropdown){
    setDropdowns({
      time: dropdown === 'time' ? true : false,
      status: dropdown === 'status' ? true : false,
    })
  }


  

  const status = {
    'pending':"قيد الأنتظار",
    'done':"مكتملة",
  }
  useEffect(() => {
    async function loadData(){
      await axios.get(`${url}/employees/${props.id}/sessions`, {withCredentials:true})
                .then(res => {
                  setData(res.data.data)
                  setIsLoading(false)
                })
                .catch(err => {
                  if(err){
                    console.log(err.response.data.message)
                    setIsLoading(false)
                  }
                })
    }
    if(isLoading){
      loadData()
    }
    if(data){
      const filterDataByTimeMethods = {
        getRange: (data, range, status) => {
          const now = new Date()
          const oneWeekAgo = new Date(now)
          oneWeekAgo.setDate(now.getDate() - range)
          const timeResults = {
            start:oneWeekAgo,
            end:now
          }
          const { start, end } = timeResults;
        
          return data.filter(item => {
            const itemDate = new Date(item.start);
            return itemDate >= start && itemDate <= end;
          }).filter(item => status === '' ? item : item.status === status)
        },
      }
      const d = filterDataByTimeMethods.getRange(data, 1, '')
      setFilteredData(d)
    }
  }, [data,setFilteredData, isLoading, props])
  useEffect(() => {
    if(data){
      const filterDataByTimeMethods = {
        getRange: (data, range, status) => {
          const now = new Date()
          const oneWeekAgo = new Date(now)
          oneWeekAgo.setDate(now.getDate() - range)
          const timeResults = {
            start:oneWeekAgo,
            end:now
          }
          const { start, end } = timeResults;
        
          return data.filter(item => {
            const itemDate = new Date(item.start);
            return itemDate >= start && itemDate <= end;
          }).filter(item => status === '' ? item : item.status === status)
        },
      }
      const d = filterDataByTimeMethods.getRange(data, filters.time, filters.status)
      setFilteredData(d)
    }
  }, [filters, data])
  return (
    <>
      <div className='section-title'>
        <h1>الجلسات</h1>
      </div>
      <div className='sessionFilter'>
          <div className={filters.time === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'}>
                <div className='time'  onClick={() => {
                  openDropdown('time')
                }}
              >{timeKeys[filters.time]}</div>
                <div  className={dropdowns.time ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    <li onClick={() => {
                      setFilters({...filters, time:1})
                      openDropdown('')
                      }}>اليوم</li>
                    <li onClick={() => {
                      setFilters({...filters, time:7})
                      openDropdown('')
                      }}>الأسبوع الماضي</li>
                    <li onClick={() => {
                      setFilters({...filters, time:30})
                      openDropdown('')
                      }}>الشهر الماضي</li>
                    <li onClick={() => {
                      setFilters({...filters, time:100000})
                      openDropdown('')
                      }}>كل الوقت</li>
                  </ul>
                </div>
          </div>
          <div className={filters.status === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'}>
                <div className='status'  onClick={() => {
                  openDropdown('status')
                }}
              >{status[filters.status] || 'الجميع'}</div>
                <div  className={dropdowns.status ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    <li onClick={() => {
                      setFilters({...filters, status:""})
                      openDropdown('')
                      }}>الجميع</li>
                    <li onClick={() => {
                      setFilters({...filters, status:sessionsStatus.PENDTING})
                      openDropdown('')
                      }}>قيد الأنتظار</li>
                    <li onClick={() => {
                      setFilters({...filters, status:sessionsStatus.DONE})
                      openDropdown('')
                      }}>مكتملة</li>
                  </ul>
                </div>
          </div>
      </div>
       <div className='table-container'>
            {
              !isLoading && filteredData.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <th>
                        أسم المراجع
                      </th>
                      <th>
                        الخدمة
                      </th>
                      <th>
                        التاريخ
                      </th>
                      <th>
                        الوقت
                      </th>
                      <th>
                        الحالة
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredData.map((d, i) => {
                        return (
                          <tr key={i}>
                            <td>{d.patient}</td>
                            <td>{d.service}</td>
                            <td>{formatDate(d.start)}</td>
                            <td>{getTime(d.startHour)}</td>
                            <td>{status[d.status]}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              ) : (
                <div className='not-found'>
                  <div className='image'>
                    <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
                  </div>
                  <div>
                    <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
                  </div>
                </div>
              )
            }
          </div>
    </>
  )
}

export default EmployeeSessions