import React, {useEffect, useState} from 'react'
import { BsSearch } from 'react-icons/bs'
import '../styles/formPage.css'
import Alert from '../Components/Alert'
import axios from 'axios'
import { url } from '../URL'
import UnAuthorized from './UnAuthorized'
import formatDate from './formatDate'
function AllStorageMaterials() {
  const [search, setSearch] = useState("")
  const [isAlertActive, setIsAlertActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [productID, setProductID] = useState('')
  const [msg, setMsg] = useState("")
  async function loadData(){
    await axios.get(`${url}/storage/`, {withCredentials:true})
                .then(res => {
                  setData(res.data.data)
                  setIsLoading(false)
                })
                .catch(err => {
                  if(err.response.status === 401){
                    setUnAuthorized(true)
                  }
                  console.log(err)
                  setIsLoading(false)
                })
  }
  useEffect(() => {
    if (isLoading){
      loadData()
    }
  })
  const [unAuthorized, setUnAuthorized] = useState(false)

  async function deleteProduct(id){
    await axios.delete(`${url}/storage/${id}/delete`, {withCredentials:true})
              .then(res => {
                setMsg(res.data.message)
                setIsAlertActive(false)
              })
              .catch(err => {
                if(err.response.status === 401){
                  setUnAuthorized(true)
                }
                console.log(err)
                setMsg("خطأ")
                setIsAlertActive(false)
              })
  }
  return unAuthorized ? <UnAuthorized /> : (
    <main className='page-container form-page'>
        <div className={isAlertActive ? 'shadow active' : 'shadow'} onClick={() => {setIsAlertActive(false)}}></div>
        <Alert classText={isAlertActive ? 'alert active' : 'alert'} dont={() => {setIsAlertActive(false)}} do={() => {deleteProduct(productID)}} />
        <div className='container'>
        <section className='head-section'>
            <h1 className='title'>جميع المواد</h1>
        </section>
        <section className='filter patients'>
            <div className='field-container'>
            <label>البحث</label>
            <div className='search-bar-grid'>
                <div className='icon'>
                    <BsSearch />
                </div>
                <div className='search-bar'>
                <input type='text' placeholder='بحث...' className='field' value={search} onChange={e => {setSearch(e.target.value)}} />
              </div>
            </div>
          </div>
        </section>
        <section className='patients'>
          <div className='table-container'>
          <table>
            <thead>
              <tr>
                <th>
                  أسم المادة
                </th>
                <th>
                  أسم المجهز
                </th>
                <th>
                تاريخ الشراء
                </th>
                <th>
                تاريخ انتهاء الصلاحية
                </th>
                <th>
                  الوحدة
                </th>
                <th>
                  سعر الشراء
                </th>
                <th>
                  سعر البيع
                </th>
                <th>
                  الكمية
                </th>
                <th>
                  حذف
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data ? data.filter(item => {return search !== '' ? item.name.includes(search) : item}).map((d, i) => {
                  return (
                    <tr key={i}>
                      <td>{d.name}</td>
                      <td>{d.preparerName}</td>
                      <td>{formatDate(d.purchaseDate)}</td>
                      <td>{formatDate(d.expire)}</td>
                      <td>{d.unit}</td>
                      <td>{d.purchasingPrice}</td>
                      <td>{d.sellingPrice}</td>
                      <td>{d.quantity}</td>
                      <td>
                        <button onClick={() => {
                          setProductID(d._id)
                          setIsAlertActive(true)
                          }}>حذف</button>
                      </td>
                    </tr>
                  )
                }) : 'لا توجد نتائج'
              }
            </tbody>
          </table>
          <p style={{color:"var(--primary)", textAlign:'center', fontSize:"var(--paragraph)", marginTop:"1rem"}}>{msg}</p>
          </div>
          <div className='not-found' style={{display: 'filteredData'.length > 0 ? "none" : "flex"}}>
            <div className='image'>
              <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
            </div>
            <div>
              <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
            </div>
          </div>
        </section>
      </div>  
    </main>        
  )
}

export default AllStorageMaterials