function getTime(time){
    var arr = time.split(':')
    arr[0] = parseInt(arr[0])
    const hour =  arr[0] > 12 ? arr[0] - 12 : arr[0] 
    // const hour =  arr[0]
    const mins = arr[1]
    const state = arr[0] >= 12 ? 'م' : "ص"
    return `${hour === 0 ? 12 : hour}:${mins} ${state}`
}

export default getTime