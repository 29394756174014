import React from 'react'
import '../styles/LaserDetails.css'
function LaserDetails(props) {
  return (
    <div className={props.isActive ? 'laser-details-container active' : 'laser-details-container'}>
        <h3>تفاصيل جلسة الليزر للمراجع {props.data.patient}</h3>
        <ul>
            <li>
                المنطقة: {props.data.service}
            </li>
            <li>رقم العدسة: {props.data.lensNumber}</li>
            <li>الطول الموجي: {props.data.wavelength}</li>
            <li>Pulse Duration: {props.data.pulseDuration}</li>
            <li>Fluence Number: {props.data.fluenceNumber}</li>
            <li>الجهاز المستخدم: {props.data.machineName}</li>
        </ul>
    </div>
  )
}

export default LaserDetails