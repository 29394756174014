import React, { useState } from 'react'
import '../styles/LaserPrompt.css'
import '../styles/formPage.css'
function LaserPrompt(props) {
    // const [props.laserData, setprops.laserData] = useState({
    //     wavelength:"",
    //     lensNumber:0,
    //     machineName:'',
    //     pulseDuration:0,
    //     count:0,
    //     fluenceNumber:0
    // })

    const [dropdowns, setDropdowns] = useState({
        wavelength:false
    })
    function openDropdown(dropdown){
        setDropdowns({
            wavelength: dropdown === 'wavelength' ? true : false
        })
    }
    const waveLengthLabels = [
        'Alex', "YAG"
    ]
  return (
    <div id='laserPrompt' className={props.isActive ? 'form-page active' : 'form-page'}>
        <div>
            <h2>تفاصيل الليزر للمراجع</h2>
            <p>يرجى ملء تفاصيل الليزر الذي تم تطبيقه على المراجع</p>
        </div>
        <div>
            <form className='form'>
                   <div className='row'>
                   <div className='field-container' style={{margin:".5rem 0"}}>
                        <input className={props.laserData.lensNumber === '' ? 'field' : 'field active'} type='number' name='lens-number' value={props.laserData.lensNumber} onChange={(e) => {props.handleChange('lensNumber', e.target.value)}}  />
                        <label className={props.laserData.lensNumber === '' ? '' : 'active'} htmlFor='lens-number'>رقم العدسة</label>
                    </div>
                    <div className='field-container' style={{margin:".5rem 0"}}>
                        <input className={props.laserData.pulseDuration === '' ? 'field' : 'field active'} type='number' name='pulse-duration' value={props.laserData.pulseDuration} onChange={(e) => {props.handleChange('pulseDuration', e.target.value)}}/>
                        <label className={props.laserData.pulseDuration === '' ? '' : 'active'} htmlFor='pulse-duration'>Pulse Duration</label>
                    </div>
                    <div className={'field-container dropdown-container'} style={{margin:".5rem 0"}}>
                        <label className='active'>Wavelength</label>
                        <div className='service'  onClick={() => {
                        openDropdown('wavelength')
                        }}
                    >{props.laserData.wavelength || "غير محدد"}</div>
                        <div  className={dropdowns.wavelength ? 'dropdown active' : "dropdown"} style={{margin:".5rem 0"}}>
                        <ul>
                            {
                                waveLengthLabels ? waveLengthLabels.map((wavelength, index) => {
                                    return (
                                        <li key={index} onClick={() => {
                                            props.handleChange("wavelength", wavelength)
                                            openDropdown('')
                                        }}>{wavelength}</li>
                                    )
                                }) : ''
                            }
                        </ul>
                        </div>
                    </div>
                    <div className='field-container' style={{margin:".5rem 0"}}>
                        <input className={props.laserData.fluenceNumber === '' ? 'field' : 'field active'} type='number' name='fluence-number' value={props.laserData.fluenceNumber} onChange={(e) => {props.handleChange('fluenceNumber', e.target.value)}}  />
                        <label className={props.laserData.fluenceNumber === '' ? '' : 'active'} htmlFor='fluence-number'>Fluence Number</label>
                    </div>
                    <div className='field-container' style={{margin:".5rem 0"}}>
                        <input className={props.laserData.count === '' ? 'field' : 'field active'} type='number' name='count' value={props.laserData.count}  onChange={(e) => {props.handleChange('count', e.target.value)}}  />
                        <label className={props.laserData.count === '' ? '' : 'active'} htmlFor='count'>العدد</label>
                    </div>
                    <div className='field-container' style={{margin:".5rem 0"}}>
                        <input className={props.laserData.machineName === '' ? 'field' : 'field active'} type='text' name='device-name' value={props.laserData.machineName} onChange={(e) => {props.handleChange('machineName', e.target.value)}} />
                        <label className={props.laserData.machineName === '' ? '' : 'active'} htmlFor='device-name'>أسم الجهاز</label>
                    </div>
                   </div>
                   <div className='row'>
                    <button onClick={(e) => {
                        e.preventDefault()
                        props.submit()
                    }}>أكمال الجلسة</button>
                   </div>
            </form>
        </div>
    </div>
  )
}

export default LaserPrompt