import React, {useState} from 'react'
import '../styles/AfterNotes.css'
import axios from 'axios'
import { url } from '../URL'
import LoadingIcon from '../Components/LoadingIcon'
function AfterNote(props) {

    const [notes, setNotes] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    async function sendNewNotes(id){
        setIsLoading(true)
        await axios.put(`${url}/sessions/${id}/add-after-note`, {data:{notes}}, {withCredentials:true})
                    .then(res => {
                        if(res.status === 200){
                            props.cancel()
                        }
                        setIsLoading(false)
                    })
                    .catch(err => {
                        console.log(err.response.data)
                        setIsLoading(false)
                    })
    }
  return (
    <div className={props.isActive ? 'after-notes active' : 'after-notes'}>
        <h1>أضافة ملاحظات</h1>
        <p>يمكنك أضافة اي ملاحظات اضافية عن المراجع بعد اكمال الجلسة</p>
        <form className='form'>
            <div className='row'>
                <div className='field-container'>
                    <textarea className='field' name='notes' value={notes} onChange={(e) => {
                        setNotes(e.target.value)
                    }} style={{height:'100px !important', resize:"none"}}></textarea>
                </div>
            </div>
            <div className='row'>
                <button onClick={e => {
                    e.preventDefault()
                    sendNewNotes(props.sessionID)
                }}>
                    <div>
                    أضافة   
                    </div>
                    <div>
                        <LoadingIcon active={isLoading} />
                    </div>
                </button>
            </div>
            <div className='row'>
                <button className='cancel' onClick={e => {
                    e.preventDefault()
                    props.cancel()
                }}>الغاء</button>
            </div>
        </form>
    </div>
  )
}

export default AfterNote