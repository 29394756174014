import React from 'react'

function NotFound() {
  return (
    <div className="not-found" style={{ display: 'flex' }}>
              <div className="image">
                <img src={require('../Assets/Images/not found.png')} alt="not-found" loading="lazy" />
              </div>
              <div>
                <h1>
                  <span>عذراً, </span>يبدو أن لا يوجد هناك نتائج
                </h1>
              </div>
            </div>
  )
}

export default NotFound