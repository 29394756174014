import React, { useEffect, useState } from 'react'
import '../styles/AddReceiptPictures.css'
import '../styles/formPage.css'
import { url } from '../URL'
import axios from 'axios'
import ReceiptPaper from '../Components/ReceiptPaper'
import html2canvas from 'html2canvas'
function AddReceiptPictures() {
  const [data, setData] = useState([])
  const [current, setcurrent] = useState(-1)
  const [isLoading, setIsLoading] = useState(true)
  async function loadData(){
    await axios.get(`${url}/receipts`, {withCredentials:true})
                .then(res => {
                    setData(res.data.data)
                    setIsLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setIsLoading(false)
                })
  }
  async function fetchImgs(index) {
    if(data[index]){
      const imageName = data[index].image.replace('/public/receipts/', '');
      const element = document.querySelector('#receiptsPaper');

      await new Promise(resolve => setTimeout(resolve, 100)); // Wait 100ms

      // Generate canvas
      const canvas = await html2canvas(element, { useCORS: true });
      const blobData = canvas.toDataURL('image/jpeg', 0.5);

        await axios.post(`${url}/receipts/generate-receipts-images`,{ data: { blobData, filename: imageName } }, { withCredentials: true })
                    .then(res => {
                      setcurrent(d => {return d + 1})
                    })
    }
  }

  const [isStarted, setIsStarted] = useState(false)
  useEffect(() => {
    if(isStarted){
      // setTimeout(() => {
        fetchImgs(current)
      // }, 1000);
    }
  }, [current])
  
  
  useEffect(() => {

    if(isLoading){
      loadData()
    }
  }, [isLoading])
  return (
    <main className='page-container form-page'>
      <div className='container'>
          <section className='head-section'>
            <h1 className='title'>أضافة صور للوصولات</h1>
          </section>
          <section>
            <div>
              {
                !isLoading ? data[current] ? <ReceiptPaper sessionData={data[current]} /> : "اضغط على الزر لاعادة انشاء صور الوصولات, قد تستغرق العملية بعض الوقت" : "Loading ..."
              }
            </div>
            <button onClick={e => {
              e.preventDefault()
              setIsStarted(true)
              setcurrent(0)
            }} style={{background:'var(--primary)', color:'var(--bg)', width:'fit-content', padding:'1rem 2rem', marginTop:'1rem'}}>أعادة أنشاء</button>
          </section>
      </div>
    </main>
  )
}

export default AddReceiptPictures