import React from 'react'
import {PuffLoader} from 'react-spinners'
function Loading() {
  return (
    <div style={{width:'100%', height:'50vh', display:"flex", alignItems:"center", justifyContent:"center"}}>
        <PuffLoader color={'#228a83'} />
    </div>
  )
}

export default Loading