import React, {useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { url } from '../URL'
import axios from 'axios'
import Loading from './Loading'
import formatDate from '../Components/formatDate'
import '../styles/RecieptPage.css'
import '../styles/EditReceipt.css'
import '../styles/formPage.css'
import ReceiptPaper from '../Components/ReceiptPaper'
import html2canvas from 'html2canvas'
function EditReceipt() {
    const location = useLocation()
    const id = location.pathname.split('/')[2] 
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    async function loadData(){
        await axios.get(`${url}/receipts/${id}`, {withCredentials:true})
                    .then(res => {
                        setData(res.data.data)
                        setIsLoading(false)
                    })
                    .catch(err => {
                        console.log(err)
                        setIsLoading(false)
                    })
    }
    useEffect(() => {
        if(isLoading){
            loadData()
        }
    })
    async function finishEditing(data){
        const element = document.getElementById("receiptsPaper")
        const canvas = await html2canvas(element)
        const blobData = canvas.toDataURL("image/png"); 
        await axios.put(`${url}/receipts/${id}/edit`, {data: {...data, blobData}}, {withCredentials:true})
                    .then(res => {
                        if(res.status === 200 || res.status === 201){
                            alert("تم تعديل الجلسة بنجاح")
                            window.location.reload()
                        }
                    }).catch(err => {
                        alert(err.response.data.message)
                    })
    }
  return isLoading ? <Loading /> : (
    <main className='page-container one-receipt edit-receipt'>
        <div className='image-container'>
            <ReceiptPaper sessionData={data} />
        </div>
        <div className='form'>
            <section>
                <div>
                    <h1 className='title'>معلومات الوصل</h1>
                </div>
                <div className='section-content'>
                    <h2>الوصل رقم #{data.receiptId}</h2>
                    <div style={{display:"flex", gap:"1rem"}}>
                        <h1>المراجع </h1>
                        <input name='name' id='name' type='text' onChange={e => {
                            setData(d => {
                                return {...d, patient:e.target.value}
                            })
                        }}  value={data.patient} />
                    </div>
                    <ul>
                        <li><span>تاريخ الوصل:</span> {formatDate(data.date)}</li>
                        <li><span>وقت الوصل:</span> {data.hour}</li>
                        <li><span>رقم الهاتف:</span> {data.phone}</li>
                        <li><span>الخدمة:</span> {data.service}</li>
                        <li><span>المبلغ المدفوع:</span> <input name='name' id='name' type='number' className='small' value={data.price} onChange={e => {
                            setData(d => {
                                return {...d, price:e.target.value}
                            })
                        }} /> د.ع</li>
                        <li><span>المسؤول عن الوصل:</span> {data.responsible}</li>
                    </ul>
                </div>
            </section>
            <section>
                <h1 className='title'>خيارات</h1>
                <div className='section-content'>
                    <button onClick={(e) => {
                        e.preventDefault()
                        finishEditing(data)
                    }}>أتمام التعديل</button>
                        <button style={{background:'#e6454d !important', pointerEvents:"all"}} className='delete-btn' onClick={e => {
                            e.preventDefault()
                        }}>
                    <Link to={`/receipts/${id}/`} style={{color:"white"}}>
                            الغاء 
                    </Link>
                            </button>
                </div>
            </section>
        </div>
    </main>
  )
}

export default EditReceipt