import React, {useState, useEffect} from 'react'
import { BsSearch } from 'react-icons/bs'
import '../styles/formPage.css'
import Alert from '../Components/Alert'
import { url } from '../URL'
import axios from 'axios'
import UnAuthorized from './UnAuthorized'
import formatDate from './formatDate'
function AllCosmeticsMaterials() {
  const [search, setSearch] = useState("")
  const [isAlertActive, setIsAlertActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [dataID, setDataID] = useState('')
  const [msg, setMsg] = useState('')
  const [unAuthorized, setUnAuthorized] = useState(false)
  async function loadData(){
    await axios.get(`${url}/cosmetics/`, {withCredentials:true})
              .then(res => {
                setData(res.data.data)
                setMsg(res.data.message)
                console.log(res.data.data)
                setIsAlertActive(false)
                setIsLoading(false)
              })
              .catch(err => {
                if(err.response.status === 401){
                  setUnAuthorized(true)
                }
                console.log(err)
                setMsg('خطا')
                setIsAlertActive(false)
                setIsLoading(false)
              })
  }

  async function deleteData(id){
    await axios.delete(`${url}/cosmetics/${id}/delete`, {withCredentials:true})
              .then((res) => {
                setMsg(res.data.message)
                setIsAlertActive(false)
              })
              .then(err => {
                console.log(err)
                setIsAlertActive(false)
              })
  }
  useEffect(() => {
    if(isLoading){
      loadData()
    }
  })
  useEffect(() => {
    function filterData(d){
      const searchedResults = d.filter(data => data.name.includes(search));
      return searchedResults;
    }
  
    const d = filterData(data);
    setFilteredData(d);
  }, [data, search]);
  
  return unAuthorized ? <UnAuthorized/> : (
    <main className='page-container form-page'>
        <div className={isAlertActive ? 'shadow active' : 'shadow'} onClick={() => {setIsAlertActive(false)}}></div>
        <Alert classText={isAlertActive ? 'alert active' : 'alert'} dont={() => {setIsAlertActive(false)}} do={() => {deleteData(dataID)}} />
        <div className='container'>
        <section className='head-section'>
            <h1 className='title'>جميع المواد</h1>
        </section>
        <section className='filter patients' style={{gridTemplateColumns:"100%"}}>
            <div className='field-container'>
            <label>البحث</label>
            <div className='search-bar-grid'>
                <div className='icon'>
                    <BsSearch />
                </div>
                <div className='search-bar'>
                <input type='text' placeholder='بحث...' className='field' value={search} onChange={e => {setSearch(e.target.value)}} />
              </div>
            </div>
          </div>
        </section>
        <section className='patients'>
          <div className='table-container'>
            {
              filteredData.length > 0 ? (
                <>
                <table>
                  <thead>
                    <tr>
                      <th>
                        أسم المادة
                      </th>
                      <th>
                        أسم المجهز
                      </th>
                      <th>
                        تاريخ الشراء
                      </th>
                      <th>
                        تاريخ انتهاء الصلاحية
                      </th>
                      <th>
                        سعر الشراء
                      </th>
                      <th>
                        سعر البيع
                      </th>
                      <th>
                        الكمية
                      </th>
                      <th>
                        حذف
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredData.map((d, i) => {
                        return (
                          <tr key={i}>
                            <td>{d.name}</td>
                            <td>{d.preparerName}</td>
                            <td>{formatDate(d.purchaseDate)}</td>
                            <td>{formatDate(d.expire)}</td>
                            <td>{d.purchasingPrice}</td>
                            <td>{d.sellingPrice}</td>
                            <td>{d.quantity}</td>
                            <td>
                              <button onClick={() => {
                                setDataID(d._id)
                                setIsAlertActive(true)
                                }}>حذف</button>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                  <div>
                    <p style={{color:"var(--primary)", marginTop:'1rem', textAlign:'center', fontSize:"var(--paragraph)"}}>{msg}</p>
                  </div>
                  </>
              ) : <div className='not-found' style={{display: "flex"}}>
              <div className='image'>
                <img src={require('../Assets/Images/not found.png')} alt='not-found' loading='lazy' />
              </div>
              <div>
                <h1><span>عذراً, </span>يبدو أن لا يوجد هناك نتائج</h1>
              </div>
            </div>
            }
          </div>
        </section>
      </div>  
    </main>        
  )
}

export default AllCosmeticsMaterials